<template>
    <div>
        <router-view ref="routeview"></router-view>
    </div>
</template>

<script>
export default {
    name: 'AgentsWrapper',
}
</script>
